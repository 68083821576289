import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './CenterAuthenticationForm.scss';
import { addAPIUserDispatch } from '../../redux/actions';
import { isValidIpChars, replaceText } from '../../helper';
import {
    getPasswordPolicyForDispatchCenter,
    updatePasswordPolicyConfiguration,
} from '../../api/passwordPolicyAPI';
import { DispatchCenter } from '../../types';
import { DEFAULT_TIMEOUT } from '../../config';
import { updateCenterDispatch } from '../../redux/actions/dispatchCenters';
import { getAzureStatus } from '../../api/api';

/**
 * Form to create an api access token for this center
 */

interface CenterAuthenticationProps {
    texts: [key: string];
    apiToken: string;
    currentUserOrganization: currentUserOrganization;
    match: match;
    centers: Array<DispatchCenter>;
}

interface currentUserOrganization {
    id: number;
    name: string;
    ipRestriction: string;
}

interface match {
    params: any;
    isExact: any;
    path: any;
    url: any;
}

const CenterAuthenticationComponent: React.FC<CenterAuthenticationProps> = ({
    texts,
    apiToken,
    currentUserOrganization,
    match,
    centers,
}) => {
    const [hasMinimumLength, setHasMinimumLength] = useState(false);
    const [hasMixedCase, setHasMixedCase] = useState(false);
    const [hasSpecialCharacters, setHasSpecialCharacters] = useState(false);
    const [hasNumerics, setHasNumerics] = useState(false);
    const [compareLastPassword, setCompareLastPassword] = useState(false);
    const [passwordMinimumLength, setPasswordMinimumLength] = useState(8);
    const [passwordExpirationDate, setPasswordExpirationDate] = useState(0);

    const [policiesSaved, setPoliciesSaved] = useState(false);
    const [ipRestrictionsSaved, setIpRestrictionsSaved] = useState(false);

    const [ipRestrictionInput, setIpRestrictionInput] = useState('');
    const [ipHasError, setIpHasError] = useState(false);

    const [isAzureSystem, setIsAzureSystem] = useState(false);

    const orgId = match.params.id;
    const currentCenter = centers.filter(
        center => Number(orgId) === center.id
    )[0];

    let areaRef = useRef<HTMLTextAreaElement | null>(null);
    const ipRestrictionRef = useRef<HTMLTextAreaElement | null>(null);
    const ipRestrictionClasses = `ip-restriction ${ipHasError ? 'has-error' : ''}`;

    const setPolicies = (policy: string) => {
        switch (true) {
            case policy === 'MIXED_CASE':
                setHasMixedCase(true);
                break;
            case policy === 'CHAR_COUNT':
                setHasMinimumLength(true);
                break;
            case policy === 'SPECIAL_CHARS':
                setHasSpecialCharacters(true);
                break;
            case policy === 'NUMERICS':
                setHasNumerics(true);
                break;
            case policy === 'LAST_PASSWORD':
                setCompareLastPassword(true);
                break;
            default:
                break;
        }
    };

    const getUpdatedPolicies = () => {
        let policiesToSave = [];
        if (hasMinimumLength) {
            policiesToSave = [...policiesToSave, 'CHAR_COUNT'];
        }
        if (hasMixedCase) {
            policiesToSave = [...policiesToSave, 'MIXED_CASE'];
        }
        if (hasSpecialCharacters) {
            policiesToSave = [...policiesToSave, 'SPECIAL_CHARS'];
        }
        if (hasNumerics) {
            policiesToSave = [...policiesToSave, 'NUMERICS'];
        }
        if (compareLastPassword) {
            policiesToSave = [...policiesToSave, 'LAST_PASSWORD'];
        }

        return policiesToSave;
    };

    const validatePasswordExpiry = (e: any) => {
        if (Number(e.currentTarget.value) > 99) {
            return;
        } else if (Number(e.currentTarget.value) < 0) {
            setPasswordExpirationDate(0);
        } else {
            setPasswordExpirationDate(Number(e.currentTarget.value));
        }
    };

    const validateMinimumLength = (e: any) => {
        if (Number(e.currentTarget.value) > 30) {
            return;
        } else if (Number(e.currentTarget.value) < 8) {
            setPasswordMinimumLength(8);
        } else {
            setPasswordMinimumLength(e.currentTarget.value);
        }
    };

    const savePolicies = async e => {
        e.preventDefault();
        let enabledPolicies = getUpdatedPolicies();
        await updatePasswordPolicyConfiguration({
            addOnId: Number(currentCenter.id),
            enabledPolicies: enabledPolicies,
            minimumPasswordLength: passwordMinimumLength,
            passwordExpirationLength: passwordExpirationDate,
        });
        setPoliciesSaved(true);
        setTimeout(() => {
            setPoliciesSaved(false);
        }, DEFAULT_TIMEOUT);
    };

    const addApiUser = event => {
        event.preventDefault();
        addAPIUserDispatch();
    };

    const copyToClipboard = event => {
        event.preventDefault();
        if (areaRef?.current) {
            navigator.clipboard.writeText(areaRef.current.textContent).then(() => {
            window.alert(replaceText(texts, 'token.copied'));
            }).catch(err => {
                console.log(err);
            })
        }
    };

    const readToken = apiToken => {
        if (apiToken === '') return { exp: '', iat: '' };
        const parse = JSON.parse(atob(apiToken.split('.')[1]));
        const exp = new Date(parse.exp * 1000).toLocaleDateString(
            navigator.language,
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            }
        );
        const iat = new Date(parse.iat * 1000).toLocaleDateString(
            navigator.language,
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            }
        );
        return { exp, iat };
    };

    const { exp, iat } = readToken(apiToken);
    const userOrgId = `${currentUserOrganization.id}`;

    const validateIpRestrictionInput = () => {
        const ipInputValue = ipRestrictionRef.current.value;

        if (isValidIpChars(ipInputValue)) {
            setIpHasError(false);
            return false;
        } else {
            setIpHasError(true);
            return true;
        }
    };

     const updateIpRestrictionInput = () => {
         setIpRestrictionInput(ipRestrictionRef.current.value);
          console.log(ipRestrictionRef.current.value);
     };

    const saveIpRestriction = async () => {
        if (validateIpRestrictionInput()) {
            return;
        }
        
        if (
            typeof currentCenter.id === 'number' &&
            !Number.isNaN(currentCenter.id)
        ) {
            const updatedCenter = {
                ...currentCenter,
                ipRestriction: ipRestrictionInput,
            };

            await updateCenterDispatch(updatedCenter);
        }
        setIpRestrictionsSaved(true);
        setTimeout(() => {
            setIpRestrictionsSaved(false);
        }, DEFAULT_TIMEOUT);
    };

    useEffect(() => {
        const loadPasswordPolicyData = async () => {
            const data = await getPasswordPolicyForDispatchCenter(
                currentCenter.id
            );
            if (data) {
                if (data.enabledPolicies.length !== 0) {
                    data.enabledPolicies.forEach(policy => {
                        setPolicies(policy);
                    });
                }
                setPasswordMinimumLength(data.minimumPasswordLength);
                setPasswordExpirationDate(data.passwordExpirationLength);
            }
        };

        const loadIpRestriction = () => {
            if (currentCenter.id && currentCenter.ipRestriction) {
                setIpRestrictionInput(currentCenter.ipRestriction);
            }
        };

        const fetchAzureSystemStatus = async () => {
            setIsAzureSystem(await getAzureStatus());
        }

        fetchAzureSystemStatus();
        loadPasswordPolicyData();
        loadIpRestriction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="center-authentication">
            {!isAzureSystem ? (
                <form className="authentication-form">
                    <h2>{replaceText(texts, 'password.policy.header')}</h2>
                    <p>{replaceText(texts, 'password.policy.description')}</p>
                    <div className="authentication-form__row">
                        <div className="flex">
                            <h3>
                                {replaceText(
                                    texts,
                                    'password.requirement.header'
                                )}
                            </h3>
                            <div>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={hasMinimumLength}
                                        onChange={() => {}}
                                        onClick={() => {
                                            setHasMinimumLength(
                                                !hasMinimumLength
                                            );
                                        }}></input>
                                    {replaceText(
                                        texts,
                                        'password.requirement.minLength'
                                    )}
                                </label>
                                {hasMinimumLength ? (
                                    <input
                                        type="number"
                                        value={passwordMinimumLength}
                                        onChange={
                                            validateMinimumLength
                                        }></input>
                                ) : (
                                    ''
                                )}
                            </div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={hasMixedCase}
                                    onChange={() => {}}
                                    onClick={() => {
                                        setHasMixedCase(!hasMixedCase);
                                    }}></input>
                                {replaceText(
                                    texts,
                                    'password.requirement.mixedCase'
                                )}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={hasSpecialCharacters}
                                    onChange={() => {}}
                                    onClick={() => {
                                        setHasSpecialCharacters(
                                            !hasSpecialCharacters
                                        );
                                    }}></input>
                                {replaceText(
                                    texts,
                                    'password.requirement.specialChars'
                                )}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={hasNumerics}
                                    onChange={() => {}}
                                    onClick={() => {
                                        setHasNumerics(!hasNumerics);
                                    }}></input>
                                {replaceText(
                                    texts,
                                    'password.requirement.numerical'
                                )}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={compareLastPassword}
                                    onChange={() => {}}
                                    onClick={() => {
                                        setCompareLastPassword(
                                            !compareLastPassword
                                        );
                                    }}></input>
                                {replaceText(
                                    texts,
                                    'password.requirement.lastPassword'
                                )}
                            </label>
                            <button
                                className="btn btn--primary"
                                onClick={savePolicies}
                                disabled={policiesSaved}>
                                {replaceText(texts, 'password.button.save')}
                            </button>
                            <div
                                className={`success-message ${
                                    policiesSaved
                                        ? 'success-message--isShown'
                                        : ''
                                }`}>
                                <span>
                                    {replaceText(texts, 'settings.saved')}
                                </span>
                            </div>
                        </div>
                        <div className="flex">
                            <h3>
                                {replaceText(
                                    texts,
                                    'password.requirement.expiry'
                                )}
                            </h3>
                            <label>
                                <input
                                    type="number"
                                    value={passwordExpirationDate}
                                    onChange={validatePasswordExpiry}
                                    max="99"></input>
                                {replaceText(texts, 'time.mon')}
                            </label>
                            <span>
                                {replaceText(
                                    texts,
                                    'password.requirement.expiryDescription'
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="center-authentication__row" />
                </form>
            ) : (
                <></>
            )}
            <h2>{replaceText(texts, 'token.generate')}</h2>
            <p>{replaceText(texts, 'token.hint')}</p>
            <div className="center-authentication__generate">
                <button
                    onClick={addApiUser}
                    className="btn--primary"
                    disabled={userOrgId !== orgId}>
                    {replaceText(texts, 'token.generation')}
                </button>
                {userOrgId !== orgId && (
                    <span>{replaceText(texts, 'token.only')}</span>
                )}
            </div>
            <div className="center-authentication__token">
                <textarea
                    ref={areaRef}
                    placeholder="generiertes Token"
                    value={apiToken}
                    disabled={userOrgId !== orgId}
                    readOnly></textarea>
                {apiToken !== '' && (
                    <button className='btn btn--primary' onClick={copyToClipboard}>
                        {replaceText(texts, 'token.copy')}
                    </button>
                )}
            </div>
            {apiToken !== '' && (
                <div className="center-authentication__info">
                    <p>
                        <span>{replaceText(texts, 'token.created')}</span> {iat}
                    </p>
                    <p>
                        <span>{replaceText(texts, 'token.valid')}</span> {exp}
                    </p>
                </div>
            )}
            <div className="center-authentication__row" />
            <div className={ipRestrictionClasses}>
                <label htmlFor="ip">
                    <h2>{replaceText(texts, 'ip.restriction')}</h2>
                </label>
                <div className="centerForm__row">
                    <textarea
                        id="ip"
                        ref={ipRestrictionRef}
                        value={ipRestrictionInput}
                        onChange={updateIpRestrictionInput}
                        placeholder={replaceText(texts, 'ip.placeholder')}
                        maxLength={255}></textarea>
                    <span>
                        <span className="hint">
                            {replaceText(texts, 'max.chars', 255)}
                        </span>
                        {replaceText(texts, 'ip.hint.1')}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://regex101.com/">
                            regex101.com
                        </a>
                    </span>
                </div>
                <button
                    className="btn btn--primary"
                    onClick={saveIpRestriction}
                    disabled={ipRestrictionsSaved}>
                    {replaceText(texts, 'action.save.ipRestriction')}
                </button>
                <div
                    className={`success-message ${
                        ipRestrictionsSaved ? 'success-message--isShown' : ''
                    }`}>
                    <span>{replaceText(texts, 'settings.saved')}</span>
                </div>
                <span className="error-message">
                    {replaceText(texts, 'ip.error')}
                </span>
            </div>
        </div>
        // </div>
    );
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        apiToken: state.apiToken,
        currentUserOrganization: state.currentUser.organization,
        texts: state.texts.texts,
        centers: state.dispatchCenters.centers,
    };
};

// Connect Props and Dispatch to Component
export default withRouter(
    connect(mapStateToProps)(CenterAuthenticationComponent)
);
