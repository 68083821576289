import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';

import './Dropdown.scss';
import ArrowDown from '../icons/ArrowDown';
import { replaceText } from '../../helper';
import { connect } from 'react-redux';

/**
 * Simple custom dropdown. Calculates opening size and direction.
 */
class Dropdown extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            position: 'bottom',
            minHeight: this.props.minHeight,
            scrolling: 'auto',
        };

        this.dropdownRef = React.createRef();
        this.dropdownContentRef = React.createRef();
    }

    toggleDropdown = () => {
        this.setState(
            {
                open: !this.state.open,
                scrolling: !this.state.scrolling,
            },
            this.handlePositioning
        );
    };

    closeDropdown = () => {
        this.setState({
            open: false,
            scrolling: false,
        });
    };

    clickChild = (label, value) => {
        this.props.clickHandler({ value, label });

        this.setState({
            open: false,
            scrolling: false,
        });
    };

    handlePositioning = () => {
        if (!this.state.open) return;
        const contentRect =
            this.dropdownContentRef.current.getBoundingClientRect();
        const dropdownRect = this.dropdownRef.current.getBoundingClientRect();
        const h = window.innerHeight;
        if (this.dropdownContentRef.current.querySelector('div').children.length > 5){
            this.setState({
                scrolling: 'scroll',
            });
        }
        if (dropdownRect.top + dropdownRect.height + contentRect.height > h) {
            if (dropdownRect.top < contentRect.height) {
                this.setState({
                    position: 'bottom',
                    maxHeight: h - (dropdownRect.top + dropdownRect.height),
                });
            } else {
                this.setState({
                    position: 'top',
                    maxHeight: dropdownRect.top,
                });
            }
        } else {
            this.setState({
                position: 'bottom',
                maxHeight: h - (dropdownRect.top + dropdownRect.height),
            });
        }
    };

    render() {
        const classes = clsx('dropdown', `dropdown--${this.state.position} dropdown--${this.state.scrolling}`, {
            'dropdown--open': this.state.open,
            'dropdown--disabled': this.props.disabled,
            'dropdown--flags': this.props.hasFlags,
        });
        const styles = {
            maxHeight: this.state.maxHeight,
            minHeight: this.state.open ? this.state.minHeight : 0,
        };

        const label = this.props.label
            ? this.props.label
            : replaceText(this.props.texts, 'dropdown.default');

        return (
            <OutsideClickHandler onOutsideClick={this.closeDropdown}>
                <div className={classes} ref={this.dropdownRef}>
                    <div
                        className="dropdown__label"
                        onClick={this.toggleDropdown}>
                        <span>{label}</span>
                        <ArrowDown addClass="dropdown__toggleIcon" />
                    </div>
                    <div
                        className="dropdown__content"
                        ref={this.dropdownContentRef}
                        style={styles}>
                        {React.cloneElement(this.props.children, {
                            clickElement: this.clickChild,
                        })}
                    </div>
                </div>
            </OutsideClickHandler>
        );
    }
}
// Default Props
Dropdown.defaultProps = {
    minHeight: 20,
};

// PropTypes for this Component
Dropdown.propTypes = {
    children: PropTypes.any,
    clickHandler: PropTypes.func,
    label: PropTypes.any,
    minHeight: PropTypes.number,
    disabled: PropTypes.bool,
    hasFlags: PropTypes.bool,
    texts: PropTypes.object,
};

const mapStateToProps = state => {
    return { texts: state.texts.texts };
};

export default connect(mapStateToProps)(Dropdown);
