import { FORCE_HTTPS } from './config';
import { cssVariables, logo } from 'theme';

/**
 * add or a remove an item from an array
 * @param {array} arr
 * @param {object} item
 * @returns {arr}
 */
export const addOrRemove = (arr, item) => {
    return arr.includes(item) ? arr.filter(i => i !== item) : [...arr, item];
};

/**
 * check and force https protocol for a given url
 * @param {string} url
 * @returns {string}
 */
export const forceHTTPS = url => {
    try {
        if (!FORCE_HTTPS) return url;
        const newUrl = new URL(url);
        newUrl.protocol = 'https:';
        return newUrl.href;
    } catch (e) {
        console.error(e);
        return url;
    }
};

/**
 * change css variables from js to styles
 */
export const changeCSSVariablesTo = () => {
    Object.keys(cssVariables).forEach(key => {
        document.documentElement.style.setProperty(
            `--${key}`,
            cssVariables[key]
        );
    });
};

/**
 * get the browser title from the theme
 * @returns {string} browser title
 */
export const getBrowserTitle = () => {
    return process.env.REACT_APP_BROWSER_TITLE;
};

/**
 * get the title from the theme
 * @returns {string} title
 */
export const getTitle = () => {
    return process.env.REACT_APP_TITLE;
};

/**
 * get the logo from the theme
 * @returns {string} logo url
 */
export const getLogo = () => {
    return logo;
};

/**
 * checks if the given phone number is valid
 * @param {string} phone
 * @returns {boolean}
 */
export const isValidPhone = phone => {
    const match = phone.match(/^(\+|00)[1-9]{1}[0-9]{3,14}$/gm);
    return match && match.length > 0;
};

/**
 * checks if the given text contains only valid chars
 * @param {string} text
 * @returns {boolean}
 */
export const isValidText = text => {
    const match = text.match(
        /[\d&\s?!()\-,.A-Za-z-ÁÀȦÂÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞG̃ĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğg̃ģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼM̂M̄ʼNŃN̂ṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽm̂m̄ŉńn̂ṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠȘṢŤȚṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťțṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßœŒçÇ]*/  // eslint-disable-line no-misleading-character-class
    );
    return match.length > 0 && match[0] === text;
};

/**
 * checks if the given sms text contains only valid chars
 * @param {string} text
 * @returns {boolean}
 */
export const isValidSms = text => {
    const match = text.match(
        /[\d&\s?!()\-=/,.:;A-Za-z-ÁÀȦÂÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞG̃ĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğg̃ģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼM̂M̄ʼNŃN̂ṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽm̂m̄ŉńn̂ṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠȘṢŤȚṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťțṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßœŒçÇ{}>]*/  // eslint-disable-line no-misleading-character-class
    );
    return match.length > 0 && match[0] === text;
};

/**
 * checks if the given input contains only valid chars
 * @param {string} input
 * @returns {boolean}
 */
export const isValidIpChars = input => {
    const match = input.match(/[\d()-\][|]*/);
    return match.length > 0 && match[0] === input;
};

// utility functions for subbing in values to simple string templates in various places
export const formatUnicorn = (string, placeholders) => {
    let str = string.toString();
    if (placeholders.length) {
        const t = typeof placeholders[0];
        let key;
        const args =
            'string' === t || 'number' === t
                ? Array.prototype.slice.call(placeholders)
                : placeholders[0];

        for (key in args) {
            str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key]);
        }
    }

    return str;
};

/**
 * replaces a key from the text object with a value
 * @param {object} texts - object of translation strings
 * @param {string} id - key to replace
 * @param  {...any} placeholders
 * @returns {string} replaced value
 */
export const replaceText = (texts, id, ...placeholders) => {
    const hasElement = texts.hasOwnProperty(id);    // eslint-disable-line no-prototype-builtins
    if (!hasElement) {
        return `${id}`;
    }
    let text = texts[id];
    if (typeof text === 'string') return formatUnicorn(text, placeholders);
    if (typeof text === 'object')
        return text.map(text => formatUnicorn(text, placeholders));
};

/**
 * takes a string and return a string url
 * @param {string}
 * @returns {string}
 */

export const getImageUrlToUpload = (type) => {
    if (type === 'centerLogo')
        return process.env.REACT_APP_LOGO_UPLOAD_ENDPOINT;
    if (type === 'conferenceLogo')
        return process.env.REACT_APP_CONFERENCE_LOGO_POST;
    if (type === "backgroundImage") return process.env.REACT_APP_BACKGROUND_IMAGE_UPLOAD_ENDPOINT;
    return null;
}

/**
 * takes a string and return a string url
 * @param {string}
 * @returns {string}
 */
export const getImageUrlToFetch = (type) => {
    if (type === "centerLogo") return (process.env.REACT_APP_LOGO_ENDPOINT + '?dispatchCenterId=');
    if (type ==="conferenceLogo") return (
        process.env.REACT_APP_CONFERENCE_LOGO_ENDPOINT + '?dispatchCenterId='
    );
    if (type === "backgroundImage") return (
        process.env.REACT_APP_BACKGROUND_IMAGE_ENDPOINT + '?dispatchCenterId='
    );
    return null;
}