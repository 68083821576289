import React, { useRef, useState } from 'react';
import { uploadFile } from '../../api/imageUpload';
import { MAX_BACKGROUND_IMAGE_FILE_SIZE, MAX_UPLOAD_FILE_SIZE } from '../../config';
import Loading from '../global/Loading';
import './UploadForm.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { replaceText } from '../../helper';
import CenterImage from './CenterImage';
import { DispatchCenter, Organization } from '../../types';

interface RouteParams {
    id: string;
}
interface StateProps {
    currentUserOrganization: Organization;
    currentRole: string;
    centers: any[];
    texts: { [key: string]: string };
}

interface OwnProps {
    centerImage?: boolean;
    centerConferenceImage?: boolean;
    backgroundImage?: boolean;
    type?: string;
}

type Props = OwnProps & StateProps & RouteComponentProps<RouteParams>;

const UploadFormComponent: React.FC<Props> = ({
    match,
    currentUserOrganization,
    currentRole,
    centers,
    texts,
    type,
}) => {
    // validateSingleTrue({ centerImage, centerConferenceImage, backgroundImage });
    const fileRef = useRef<HTMLInputElement | null>(null);
    const [loading, setLoading] = useState(false);
    const [isTooBig, setIsTooBig] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const [isWrongFormat, setIsWrongFormat] = useState(false);

    const startUpload = async e => {
        if (isTooBig || isWrongFormat || isEmpty) return;
        e.preventDefault();
        const file = fileRef.current?.files?.[0];
        const centerId = isSuperadmin
            ? (currentCenter as DispatchCenter).addOnId
            : null;

        setLoading(true);

        await uploadFile({ file, centerId, type });

        setIsWrongFormat(false);
        setIsTooBig(false);
        setIsEmpty(true);
        setLoading(false);
    };

    const validate = () => {
         const maxFileSize = type === 'backgroundImage' ? MAX_BACKGROUND_IMAGE_FILE_SIZE : MAX_UPLOAD_FILE_SIZE;
        if (fileRef.current && fileRef.current.files.length === 1) {
            setIsEmpty(false);
        } else {
            setIsEmpty(true);
        }
        if (
            fileRef.current &&
            fileRef.current.files.length === 1 &&
            fileRef.current.files[0].size >= maxFileSize
        ) {
            setIsTooBig(true);
        } else {
            setIsTooBig(false);
        }
        if (
            fileRef.current &&
            fileRef.current.files.length === 1 &&
            fileRef.current.files[0].type !== 'image/jpeg' &&
            fileRef.current.files[0].type !== 'image/png'
        ) {
            setIsWrongFormat(true);
        } else {
            setIsWrongFormat(false);
        }
    };

    const userOrgId = currentUserOrganization.id;
    const orgId = parseInt(match.params.id);
    const isSuperadmin = currentRole === 'superadmin';
    let currentCenter = null;

    if (isSuperadmin)
        currentCenter = centers.filter(
            center => (center as DispatchCenter).id === orgId
        )[0];

    if (!isSuperadmin) {
        for (let i = 0; i < centers.length; i++) {
            currentCenter = centers[i];
        }
    }

    if ((currentCenter && isSuperadmin) || (currentCenter && userOrgId === orgId)) {
        return (
            <div className="upload-form">
                {loading ? (
                    <Loading text={replaceText(texts, 'logo.uploading')} />
                ) : (
                    <React.Fragment>
                        <CenterImage id={currentCenter.addOnId} type={type} />
                        <div className="upload-form__form">
                            <input
                                type="file"
                                name="file"
                                accept="image/png, image/jpeg"
                                ref={fileRef}
                                onChange={validate}
                            />

                            <div className="upload-form__button-wrapper">
                                <button
                                    className="btn btn--primary"
                                    type="submit"
                                    value="Upload Files"
                                    name="submit"
                                    disabled={
                                        isWrongFormat || isTooBig || isEmpty
                                    }
                                    onClick={startUpload}>
                                    {replaceText(texts, 'logo.upload')}
                                </button>
                                {isTooBig && (
                                    <span className="upload-form__error">
                                        {replaceText(texts, 'logo.error.size')}
                                    </span>
                                )}
                                {isWrongFormat && (
                                    <span className="upload-form__error">
                                        {replaceText(
                                            texts,
                                            'logo.error.format'
                                        )}
                                    </span>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    } else {
        return (
            <div className="upload-form">
                <span className="upload-form__hint">
                    {replaceText(texts, 'logo.only')}
                </span>
            </div>
        );
    }
};

const mapStateToProps = (state): StateProps => {
    return {
        currentUserOrganization: state.currentUser.organization,
        currentRole: state.currentUser.role,
        centers: state.dispatchCenters.centers,
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
const connector = connect(mapStateToProps);
const ConnectedUploadForm = connector(UploadFormComponent);
const WrappedUploadForm = withRouter(ConnectedUploadForm);
export const UploadForm: React.ComponentType<OwnProps> = WrappedUploadForm;
