import { EditorValue } from 'react-rte';

export enum FEATURES {
    AUDIO_STREAM = 'AUDIO_STREAM',
    NOTES = 'NOTES',
    PHONEBOOK = 'PHONEBOOK',
    HD_SEND = 'HD_SEND',
    BIDI = 'BIDI',
    GEOSMS = 'GEOSMS',
    PHOTO = 'PHOTO',
    INVITE = 'INVITE',
    SMS_GUIDE = 'SMS_GUIDE',
    CURSOR = 'CURSOR',
    LOGO_UPLOAD = 'LOGO_UPLOAD',
    DRAW = 'DRAW',
    CASE_DOWNLOAD = 'CASE_DOWNLOAD',
    ORG_STRUCTURE = 'ORG_STRUCTURE',
    DISCLAIMER_DISPATCHER = 'DISCLAIMER_DISPATCHER',
    DISCLAIMER_BYSTANDER = 'DISCLAIMER_BYSTANDER',
    STREAM_RECORDING = 'STREAM_RECORDING',
    FILE_SHARE = 'FILE_SHARE',
}

export type Feature = keyof typeof FEATURES;

export type ApplicationConfig = {
    enabledFeatures: Feature[];
    id: number;
    dispatchCenterId: number;
};

export type DispatchCenter = {
    id: number;
    name: string;
    ref: string;
    organizationId: number;
    ipRestriction: string;
    addOnId: number;
    chatBlocks: string[];
    centerRef: string;
    language?: string;
    smsText: string;
    end: string;
    dispatcherDisclaimer: EditorValue;
    dispatcherCheckbox: string;
    callerDisclaimer: EditorValue;
};

export type DisclaimerConfiguration = {
    confirmationText: string;
    enabledForBystander: boolean;
    enabledForDispatcher: boolean;
    disclaimerTextDispatcher: string;
    disclaimerTextBystander: string;
    confirmationEnabledForDispatcher: boolean;
};

export type PasswordPolicyConfiguration = {
    enabledPolicies: Array<string>;
    minimumPasswordLength: number;
    passwordExpirationLength: number;
};

type Scope = {
    id: number;
    name: string;
};

export type Organization = {
    id: number;
    name: string;
    ipRestriction: string;
};

export type User = {
    activated: boolean;
    disclaimerAccpeted: boolean;
    dispatchCenter: string;
    email: string;
    id: number;
    ipRestriction: string;
    level1: string;
    level2: string;
    level3: string;
    name: string;
    organization: Organization;
    role: string;
    scopeList: Scope[];
};