import React, { useEffect, useState } from 'react';
import { deleteImage, getImage } from '../../api/imageUpload';
import Delete from '../icons/Delete';
import './CenterImage.scss';
import { connect } from 'react-redux';
import { replaceText } from '../../helper';

type CenterImageProps = {
    id?: number | null;
    texts?: { [key: string]: string };
    type?: string;
}

const CenterImage: React.FC<CenterImageProps> = ({
    id = null,
    texts,
    type
}) => {
    const [logoSrc, setLogoSrc] = useState<string | null>(null);

    // run once
    useEffect(() => {
        console.log('type: ', type);
        const getCurrentImage = async id => {
            let image = null;
            image = await getImage({ id, type });
            if (image) {
                const imageData = await image.blob();
                const objectURL = URL.createObjectURL(imageData);
                setLogoSrc(objectURL);
            }

            return true;
        };

        getCurrentImage(id);
    }, [id, type]);

    const deleteCurrentImage = async () => {
        await deleteImage({ id, type });
        setLogoSrc(null);
    };
    const renderCurrentTypeText = () => {
        if (type.toLowerCase().indexOf("logo") !== -1) {
            return replaceText(texts, 'logo.current');
        } else {
            return replaceText(texts, 'image.current');
        }
    }
    const renderNoImageAvailableText = () => {
        if (type.toLowerCase().indexOf('logo') !== -1) {
            return replaceText(texts, 'logo.none');
        } else {
            return replaceText(texts, 'image.none');
        }
    };

    return (
        <div className="center-image">
            <span>{renderCurrentTypeText()}</span>
            <span>
                {logoSrc ? (
                    <>
                        <span
                            className="center-image__delete"
                            onClick={deleteCurrentImage}>
                            <Delete /> {replaceText(texts, 'action.delete')}
                        </span>
                        <img src={logoSrc} alt="dispatch center" />
                    </>
                ) : (
                    <span className="center-image__no">
                        {renderNoImageAvailableText()}
                    </span>
                )}
            </span>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(CenterImage);
