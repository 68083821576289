import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Headline from '../global/Headline';
import Header from '../global/Header';
import CenterForm from './CenterForm';
import CenterAuthenticationForm from './CenterAuthenticationForm';
import CenterFeatures from './CenterFeatures';
import { connect } from 'react-redux';
import { Tab } from '../global/Tab';
import './EditCenter.scss';
import { useParams } from 'react-router-dom';
import { centerProptype } from '../../redux/reducers/dispatchCenters';
import { applicationProptype } from '../../redux/reducers/applicationConfigs';
import { CenterPhonebook } from './CenterPhonebook';
import { replaceText } from '../../helper';
import CenterConferenceForm from './CenterConferenceForm';
import {
    setCurrentInviteConfigDispatch,
    setCurrentSessionReportDispatch,
} from '../../redux/actions';
import SessionReportView from './SessionReportView';
import { CenterDisclaimer } from './CenterDisclaimer';
import { CenterMedia } from './CenterMedia';

/**
 * Wrapper component for the center form when editing a center
 *
 * @component Header - application header
 * @component Headline - simple headline
 * @component CenterForm - center form
 * @component CenterTokenForm - form to create an api token for this center
 * @component CenterFeatures - toggles for managing the center features
 */

const EditCenter = ({
    currentRole,
    centers,
    applicationConfigs,
    texts,
    currentInviteConfig,
    currentSessionReportConfig,
}) => {
    const isSuperadmin = currentRole === 'superadmin';
    const [view, setView] = useState('EDIT');
    const params = useParams();

    const switchToEdit = () => {
        setView('EDIT');
    };
    const switchToAuthentication = () => {
        setView('AUTHENTICATION');
    };

    const switchToPhonebook = () => {
        setView('PHONEBOOK');
    };

    const switchToFeatures = () => {
        setView('FEATURES');
    };

    const switchToShare = () => {
        setView('INVITE');
    };

    const switchToSessionReport = () => {
        setView('CASE_DOWNLOAD');
    };

    const switchToDisclaimer = () => {
        setView('DISCLAIMER');
    };

    const switchToMedia = () => {
        setView('MEDIA');
    };

    const id = parseInt(params.id);
    const currentCenter = centers.filter(center => id === center.id)[0];
    const addOnId = currentCenter.addOnId;

    const currentConfig = applicationConfigs.filter(
        config => config.dispatchCenterId === currentCenter.addOnId
    )[0];

    let phonebookFeatureEnabled = false;
    let shareFeatureEnabled = false;
    let sessionReportFeatureEnabled = false;
    let disclaimerFeatureEnabled = false;
    let mediaSettingsEnabled = false;

    if (currentConfig) {
        phonebookFeatureEnabled =
            currentConfig.enabledFeatures.indexOf('PHONEBOOK') !== -1;
        shareFeatureEnabled =
            currentConfig.enabledFeatures.indexOf('INVITE') !== -1;
        sessionReportFeatureEnabled =
            currentConfig.enabledFeatures.indexOf('CASE_DOWNLOAD') !== -1;
        disclaimerFeatureEnabled =
            currentConfig.enabledFeatures.indexOf('DISCLAIMER_BYSTANDER') !==
                -1 ||
            currentConfig.enabledFeatures.indexOf('DISCLAIMER_DISPATCHER') !==
                -1;
        mediaSettingsEnabled =
            currentConfig.enabledFeatures.indexOf('PHOTO') !== -1 ||
            currentConfig.enabledFeatures.indexOf('STREAM_RECORDING') !== -1 ||
            currentConfig.enabledFeatures.indexOf('FILE_SHARE') !== -1;
    }

    useEffect(() => {
        // If invite config exists, load it into the redux store
        if (currentInviteConfig.id === null) {
            setCurrentInviteConfigDispatch(addOnId);
        }
    }, [addOnId, currentInviteConfig.id]);

    useEffect(() => {
        // If session report config exists, load it into the redux store
        if (currentSessionReportConfig.id === null) {
            setCurrentSessionReportDispatch(addOnId);
        }
    }, [addOnId, currentSessionReportConfig.id]);

    return (
        <div className="edit-center">
            <Header showLogout={true} />
            <div className="edit-center__container container">
                <Headline
                    headline={replaceText(texts, 'edit.center.header')}
                    subline={currentCenter.name}></Headline>
                <div className="edit-center__tabs">
                    <Tab isActive={view === 'EDIT'} clickHandler={switchToEdit}>
                        {replaceText(texts, 'feature.general')}
                    </Tab>
                    <Tab
                        isActive={view === 'AUTHENTICATION'}
                        clickHandler={switchToAuthentication}>
                        {replaceText(texts, 'tab.authentication')}
                    </Tab>
                    {phonebookFeatureEnabled && (
                        <Tab
                            isActive={view === 'PHONEBOOK'}
                            clickHandler={switchToPhonebook}>
                            {replaceText(texts, 'feature.phonebook')}
                        </Tab>
                    )}
                    {shareFeatureEnabled && (
                        <Tab
                            isActive={view === 'INVITE'}
                            clickHandler={switchToShare}>
                            {replaceText(texts, 'feature.share')}
                        </Tab>
                    )}
                    {sessionReportFeatureEnabled && (
                        <Tab
                            isActive={view === 'CASE_DOWNLOAD'}
                            clickHandler={switchToSessionReport}>
                            {replaceText(texts, 'feature.sessionReport')}
                        </Tab>
                    )}
                    {disclaimerFeatureEnabled && (
                        <Tab
                            isActive={view === 'DISCLAIMER'}
                            clickHandler={switchToDisclaimer}>
                            {replaceText(texts, 'feature.disclaimer')}
                        </Tab>
                    )}
                    {mediaSettingsEnabled && (
                        <Tab
                            isActive={view === 'MEDIA'}
                            clickHandler={switchToMedia}>
                            {replaceText(texts, 'tab.media')}
                        </Tab>
                    )}
                    {isSuperadmin && (
                        <Tab
                            isActive={view === 'FEATURES'}
                            clickHandler={switchToFeatures}>
                            {replaceText(texts, 'feature.features')}
                        </Tab>
                    )}
                </div>
                <div className="flex">
                    {view === 'EDIT' && <CenterForm />}
                    {view === 'AUTHENTICATION' && <CenterAuthenticationForm />}
                    {view === 'PHONEBOOK' && (
                        <CenterPhonebook
                            id={currentCenter.addOnId}
                            centerRef={currentCenter.centerRef}
                        />
                    )}
                    {view === 'INVITE' && <CenterConferenceForm />}
                    {view === 'CASE_DOWNLOAD' && <SessionReportView />}
                    {view === 'DISCLAIMER' && (
                        <CenterDisclaimer
                            currentApplicationConfig={currentConfig}
                            currentCenter={currentCenter}
                        />
                    )}
                    {view === 'MEDIA' && (
                        <CenterMedia currentCenter={currentCenter} />
                    )}
                    {view === 'FEATURES' && isSuperadmin && <CenterFeatures />}
                </div>
            </div>
        </div>
    );
};

// PropTypes for this Component
EditCenter.propTypes = {
    currentRole: PropTypes.string,
    centers: PropTypes.arrayOf(PropTypes.shape(centerProptype)),
    applicationConfigs: PropTypes.arrayOf(PropTypes.shape(applicationProptype)),
    texts: PropTypes.object,
    currentInviteConfig: PropTypes.object,
    currentSessionReportConfig: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        currentRole: state.currentUser.role,
        centers: state.dispatchCenters.centers,
        applicationConfigs: state.applicationConfigs,
        texts: state.texts.texts,
        currentInviteConfig: state.currentInviteConfig,
        currentSessionReportConfig: state.currentSessionReportConfig,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(EditCenter);
